import {onFind} from "@elements/init-modules-in-scope";
import {findIn,closest,removeClass, findAllIn, findAll, on, off, getAttribute, addClass, hasClass, setAttribute} from '@elements/dom-utils';
import {activateScrollLock, deactivateScrollLock} from "@elements/scroll-lock";
import {renderTemplate} from "./render-template";

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init () {

        onFind('.js-mega-nav', function (item) {
            let $nav = item;
            let $toggle = findIn('.js-mega-nav__toggle', $nav);

            on('click', function () {
                if (hasClass('is-open', $nav)) {
                    removeClass('is-open', $nav);
                    removeClass('is-open', $toggle);
                    deactivateScrollLock();
                } else {
                    addClass('is-open', $nav);
                    addClass('is-open', $toggle);
                    activateScrollLock();
                }
            }, $toggle);
        });

        onFind('.js-mega-nav__list-toggle', function (toggleButton) {
            let $list = closest('.js-mega-nav__list', toggleButton);
            let $listItems = findAllIn('.js-mega-nav__list-item', $list);

            on('click', function () {
                let $parentItem = closest('.js-mega-nav__list-item', this);
                if (hasClass('is-open', $parentItem)) {
                    removeClass('is-open', $parentItem);
                } else {
                    if (isMobile()) {
                        if (closest('.mega-nav ', $parentItem)) {
                            closest('.mega-nav ', $parentItem).scrollTop = 0;
                        }
                        addClass('is-open', $parentItem);
                        addClass('is-active', this);
                    } else {
                        $listItems.forEach(function (item) {
                            removeClass('is-open', item)
                        });
                        addClass('is-open', $parentItem);
                        addClass('is-active', this);
                    }

                    window.addEventListener("click", function _listener(evt) {
                        if (!$parentItem.contains(evt.target)) {
                            removeClass('is-open', $parentItem);

                            window.removeEventListener("click", _listener, true);
                        }
                    }, true);
                }
            }, toggleButton)
        });


    let toggleOverlays = findAllIn('.js-mega-nav__toggle-overlay', document.body);
    let baseElement = findIn('.js-mega-nav', document.body);
    toggleNavOverlays();
    function toggleNavOverlays() {
        toggleOverlays.forEach(function(toggleOverlay) {
            on('click', function(evt) {
                let targetId = toggleOverlay.getAttribute('data-nav-overlay-target');
                let overlayItem = findIn(targetId, baseElement);

                let renderTemplateItem = findIn('.js-render-template', baseElement);
                if(renderTemplateItem) {
                    renderTemplate(renderTemplateItem);
                }

                if(hasClass('show-overlay', overlayItem)) {
                    hideItem(toggleOverlay);
                    hideOverlay(overlayItem);
                    removeClass('body--freezed', document.body);
                } else {
                    showItem(toggleOverlay);
                    showOverlay(overlayItem);
                    addClass('body--freezed', document.body);
                }

                function bodyClickHandler(evt) {
                    if (!closest('.main-navbar', evt.target)) {
                        evt.preventDefault();
                        hideItem(toggleOverlay);
                        hideOverlay(overlayItem);
                        removeClass('body--freezed', document.body);
                    }
                }

                function showOverlay(overlay) {
                    addClass('show-overlay', overlay);
                    setAttribute('aria-expanded', true, overlay);
                    if (!isMobile()) {
                        setTimeout(function() {
                            addBodyClickHandler(bodyClickHandler);
                        }, 300);
                    }
                }
                function hideOverlay(overlay) {
                    removeClass('show-overlay', overlay);
                    setAttribute('aria-expanded', false, overlay);
                    if (!isMobile()) {
                        removeBodyClickHandler(bodyClickHandler);
                    }
                }
                toggleOverlays = findAllIn('.js-mega-nav__toggle-overlay', document.body);
                toggleNavOverlays();
            }, toggleOverlay);
        });
    }

}

function addBodyClickHandler(bodyClickHandler) {
    on('click', bodyClickHandler, document.body);
}

function removeBodyClickHandler(bodyClickHandler) {
    off('click', bodyClickHandler, document.body);
}

function hideItem(item) {
    removeClass('is-open', item);
    removeClass('is-open--only-desktop', item);
    setAttribute('aria-expanded', false, item);
}

function hideItems(items) {
    items.forEach(function(item) {
        removeClass('is-open', item);
        removeClass('is-open--only-desktop', item);
        setAttribute('aria-expanded', false, item);
    });

}

function showItem(item) {
    addClass('is-open', item);
    setAttribute('aria-expanded', true, item);
}


