"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn,addClass, closest, on} from '@elements/dom-utils';

const selectors = {
    video: '.js-play-video__video',
    base: '.js-play-video',
    button: '.js-play-video__button'
};

export function init () {
    onFind(selectors.button, function (button) {
        let baseElement = closest(selectors.base, button);

        on('click', function () {
            let video = findIn(selectors.video, baseElement);
            video.play();
            button.setAttribute('hidden', 'hidden');
        }, button);
    });
}