"use strict";

import {find, findIn, addClass, removeClass, on} from '@elements/dom-utils';
import { onFind } from '@elements/init-modules-in-scope';

const selectors = {
    selectInput: '.js-select-chevron__input',
}

export function init() {
    onFind(selectors.selectInput, function (element) {
        on('focus', function () {
            addClass('select-chevron__input--is-open', element);
        }, element);

        on('change', function () {
            console.log("change")
            removeClass('select-chevron__input--is-open', element);
        }, element);

        on('blur', function () {
            console.log("blur")
            removeClass('select-chevron__input--is-open', element);
        }, element);
    })
}
