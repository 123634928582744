'use strict'

import {onFind} from "@elements/init-modules-in-scope";
import {findIn, hasClass, addClass, on} from "@elements/dom-utils";
import { WidgetInstance } from "friendly-challenge";

export function init() {
    onFind('.js-friendly-recaptcha', function(element) {
        console.log("FOUND CAPTCHA");
        let submitBtn = findIn('button[type="submit"]', element);
        let recaptcha = findIn('.js-friendly-recaptcha__captcha', element);

        if(!hasClass('recaptcha-finished', submitBtn)) {
            submitBtn.setAttribute('disabled', 'disabled');
        }

        function doneCallback(solution) {
            console.log("DONE CALLBACK");
            addClass('recaptcha-finished', submitBtn);
            submitBtn.removeAttribute('disabled');
        }

        const errorCallback = (err) => {
            console.log('There was an error when trying to solve the Captcha.');
            console.log(err);
        }

        let options = {
            doneCallback: doneCallback,
            errorCallback: errorCallback,
        }

        let widget = new WidgetInstance(recaptcha, options);

        widget.start();

    });
}
