"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {closest, find, findAll, findIn, on} from "@elements/dom-utils";

export function init() {
    onFind('.js-lightbox__item', function (lightboxItem) {
        on('click', function () {
            let allLightboxes = findAll('.js-lightbox');
            let lightbox = closest('.js-lightbox', lightboxItem);
            let lightGalleryInstance = find('#lg-container-' + (allLightboxes.indexOf(lightbox) + 1));
            let counterNode = findIn('.lg-counter', lightGalleryInstance);

            if (!findIn('.js-counter__line', counterNode)) {
                counterNode.innerHTML += '<div class="lg-counter__line js-counter__line"><div class="lg-counter__indicator js-lg-counter__indicator"></div></div>';
            }
            let counterIndicator = findIn('.js-lg-counter__indicator', counterNode);
            let allCount = parseInt(findIn('.lg-counter-all', counterNode).innerText);

            on('lgBeforeSlide', function () {
                setPercent(counterNode, allCount, counterIndicator);
            }, lightbox);
        }, lightboxItem)
    })
}

function setPercent(counterNode, allCount, counterIndicator) {
    let currentCount = parseInt(findIn('.lg-counter-current', counterNode).innerText);
    let percent = currentCount / allCount * 100;

    counterIndicator.style.width = percent + '%';
}