import {onFind} from "@elements/init-modules-in-scope";

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as consentOverlay from '@elements/consent-overlay';
consentOverlay.init({
        cmpTool: 'cookiehub',
        revalidateEvent: 'cookieChange'
    },
    {
        base: '.js-consent',
        template: '.js-consent__template',
        dataset: 'consent',
        button: '.js-consent__accept-btn'
    });


import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as affixNav from './affix-nav';
affixNav.init();

import * as megaNav from './mega-nav';
megaNav.init();

// Todo: Add common JS that is shared between all page types here

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as playVideo from './play-video';
playVideo.init();

import * as formValidation from '@elements/form-validation';
formValidation.init();

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init();

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element)
});

import "bootstrap/js/dist/collapse";

import * as actionChanger from '@elements/action-changer';
actionChanger.init();

import * as selectChevron from './select-chevron.js';
selectChevron.init();

// import * as hashCash from './hash-cash';
// hashCash.init();

import * as friendlyRecaptcha from './friendlyRecaptcha';
friendlyRecaptcha.init();